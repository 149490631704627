// CSS Style
import 'admin/styles/site'
import 'admin/styles/style.min'
import 'admin/styles/custom'
import 'public/custom_editor.scss'

// JS
import('admin/site')
import 'admin/plugins/notification'
import * as ActiveStorage from "@rails/activestorage"

ActiveStorage.start()

// 因為在 webpack 打包的時候，沒有瀏覽器的 window 物件，所以必須 assign 這樣才能在 rails view 使用
// Ref: https://tinyurl.com/ycrtavmd
window.jQuery = $
window.$ = $
